<template>
  <div class="card"     :class="align">

    <h4 class="section-header "
   >
      🔥 HOT THIS WEEK </h4>
    <router-link :to="{path:this.highlight.metadata.djschedule.metadata.link }"
                 class="card-animation-on-hover-no-bg"
    >

      <ResponsiveImage v-if="!small" align="text-center" :image="this.highlight.metadata.main_image.imgix_url" title="DJ Schedule"
                       caption="Tulum, Mexico" :color="primaryColor"></ResponsiveImage>
      <br v-if="!small">
      <p class="text-uppercase"
         style="font-size: 1rem;letter-spacing: 0.4rem;font-weight: lighter">{{highlightEventDate}}
      </p>
      <hr>
      <MiniArtistCard
        :class="align"
        style="margin-top: 20px"
          with-venue="true"
          :event="{title:  this.highlight.metadata.djschedule.title,
                metadata: {main_image: '',
                link: this.highlight.metadata.djschedule.metadata.link,
                                date: this.highlight.metadata.djschedule.metadata.date,
                start_time: this.highlight.metadata.djschedule.metadata.start_time,
                }}"
      ></MiniArtistCard>



    </router-link>
  </div>
</template>
<script>
import MiniArtistCard from '@/components/Cards/MiniArtistCard'
import ResponsiveImage from "@/components/HeadImage/ResponsiveImage"
import {COSMIC_BASE_URL} from "@/common/request";
import moment from "moment";

export default {
  name: 'HotThisWeek',
  created: function(){
    fetch( encodeURI( `${COSMIC_BASE_URL}&props=title,slug,metadata.main_image,metadata.djschedule&query={"type":"artisthighlight","slug":"highlight" }` ) ).then((response) => {
      response.json().then((r) => {
        this.highlight = r.objects[0];
      });
    });
  },
  data: () => {
    return {
      highlight: {},
    }
  },
  components: {MiniArtistCard, ResponsiveImage},
  props: {
    primaryColor: {
      type: String,
      default: ''
    },
    secondaryColor: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'text-left'
    }
  },
  computed: {
    highlightEventDate: {
      get() {
        moment()
        return this.highlight.metadata.djschedule.metadata.date && moment(this.highlight.metadata.djschedule.metadata.date).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
          sameElse: 'DD/MM/YYYY'
        })
      }
    }
  }
}
</script>
